<template>
  <bo-card title="Slideshow">
    <div class="d-flex align-items-center">
      <b-form-group class="mb-0 mr-3">
        <b-form-checkbox name="enable_autoplay" id="enable_autoplay" value="Y" unchecked-value="N" v-model="autoplay.enable" @change="changeAutoPlay(false)">Enable Autoplay?</b-form-checkbox>
      </b-form-group>
      <b-form-group class="mb-0" label-cols="auto" content-cols-md="4" label="Autoplay Delay" label-for="autoplayDelay">
        <b-input-group append="S" size="sm">
          <b-form-input type="number" v-model="autoplay.delay" @change="changeAutoPlay(true)" id="autoplayDelay" size="sm" min="0" />
        </b-input-group>
      </b-form-group>
    </div>
    <hr>
    <draggable tag="div" class="row gutter-2 draggable" v-model="sliderData" @end="endDrag" draggable=".slider-item">
      <b-col lg="3" md="4" class="slider-item" v-for="(slider, k) in sliderData" :key="k">
        <bo-card-img :isVideo="slider.ahi_type == 'video'" :title="slider.ahi_title_id" :src="parent.uploader(slider.ahi_image, '/assets/images/no-slide.jpg')">
          <template #info>
            <b-badge :variant="slider.ahi_is_active == 'Y' ? 'success': 'danger'">{{slider.ahi_is_active == 'Y' ? 'Active': 'Inactive'}}</b-badge>
          </template>
          <template #buttons>
            <b-button
              variant="secondary"
              size="sm"
              pill
              @click="updateSlide(slider)"
              class="btn-icon"
              triggers="hover"
              v-b-tooltip.hover="'Update'"
            >
              <i class="fas fa-pencil-alt" />
            </b-button>
            <b-button
              @click="$emit('delete', slider, {type: 'deleteSlider', idKey: 'ahi_id', obj: 'Slider'}, apiGet)"
              variant="danger"
              size="sm"
              pill
              class="btn-icon"
              v-b-tooltip.hover="'Delete'"
              v-if="sliderData.length > 1"
            >
              <i class="far fa-trash-alt" />
            </b-button>
          </template>
        </bo-card-img>
      </b-col>
      <b-col lg="3" md="4">
        <a href="javascript:;" class="card-img-adder" @click="addSlider">
          <i class="fas fa-plus"></i>
          <span>Add Slider</span>
        </a>
      </b-col>
    </draggable>

    <b-collapse class="card-collapse" v-model="sliderCollapse">
      <b-card no-body class="border mb-0">
        <b-card-header>
          <div class="d-flex justify-content-between">
            <b-card-title title-tag="h5">Slide Editor</b-card-title>
            <b-button @click="() => sliderCollapse = false" class="btn-rounded" variant="outline-secondary">
            Cancel</b-button>
          </div>
        </b-card-header>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VForm"
        >
        <b-form @submit.prevent="handleSubmit(submitSlider)">
          <b-card-body>
            <b-row>
              <b-col md="4">
                <div class="form-group">
                  <label class="control-label">Type <span class="text-danger">*</span></label>
                  <v-select name="type" v-model="slideRow.ahi_type" @input="changeAhiType" :options="typeOptions" :reduce="v => v.value" label="label"></v-select>
                  <VValidate 
                    name="Type" 
                    v-model="slideRow.ahi_type"
                    :rules="mrValidation.ahi_type" 
                  />
                </div>
                <template v-if="slideRow.ahi_type == 'image'">
                  <div class="form-group">
                    <label class="control-label">Hero Image <span class="text-danger">*</span></label>         
                    <Uploader key="upload_image" v-model="slideRow.ahi_image" type="slider"/>
                    <VValidate 
                      name="Hero Image" 
                      v-model="slideRow.ahi_image"
                      :rules="mrValidation.ahi_image" 
                    />
                  </div>
                </template>
                <template v-else-if="slideRow.ahi_type == 'video'">
                  <div class="form-group">
                    <label class="control-label">Hero Video <span class="text-danger">*</span></label>         
                    <Uploader key="upload_video" v-model="slideRow.ahi_image" :isVideo="true" type="slider_video"/>
                    <VValidate 
                      name="Hero Video" 
                      v-model="slideRow.ahi_image"
                      :rules="mrValidation.ahi_image" 
                    />
                  </div>
                </template>
                <div class="form-group">
                  <div class="form-group">
                    <label class="control-label">Status <span class="text-danger mr5">*</span></label>
                    <b-form-radio-group
                      :options="parent.Config.mr.StatusOptions"
                      v-model="slideRow.ahi_is_active"
                    />
                    <VValidate 
                      name="Status" 
                      v-model="slideRow.ahi_is_active"
                      :rules="mrValidation.ahi_is_active" 
                    />
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <b-row no-gutters>
                  <div class="col-md-12">
                    <b-card class="border mb-0">
                      <template #header>
                        <h5 class="card-title">Content (ID)</h5>
                      </template>
                      <b-row>
                        <b-col md=6>
                          <div class="form-group">
                            <label class="control-label">Title (ID) <span class="text-danger">*</span></label>
                            <b-form-input type="text" class="form-control" placeholder="Title" v-model="slideRow.ahi_title_id" />
                            <VValidate 
                                name="Title (ID)" 
                                v-model="slideRow.ahi_title_id"
                                :rules="{...mrValidation.ahi_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                              />
                          </div>
                        </b-col>
                        <b-col md=6>
                          <div class="form-group mb-0">
                            <label class="control-label">Description (ID) </label>
                            <b-form-textarea rows="3" placeholder="Description" v-model="slideRow.ahi_description_id"></b-form-textarea>
                            <VValidate 
                              name="Description (ID)" 
                              v-model="slideRow.ahi_description_id"
                              :rules="{...mrValidation.ahi_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                  <div class="col-md-12">
                    <b-card class="border mb-0">
                      <template #header>
                        <h5 class="card-title">Content (EN)</h5>
                      </template>
                      <b-row>
                        <b-col md=6>
                          <div class="form-group">
                            <label class="control-label">Title (EN)<span class="text-danger">*</span></label>
                            <b-form-input type="text" placeholder="Title" v-model="slideRow.ahi_title_en" />
                            <VValidate 
                              name="Title (EN)" 
                              v-model="slideRow.ahi_title_en"
                              :rules="{...mrValidation.ahi_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </div>
                        </b-col>
                        <b-col md=6>
                          <div class="form-group mb-0">
                            <label class="control-label">Description (EN)</label>
                            <b-form-textarea class="form-control" rows="3" placeholder="Description" v-model="slideRow.ahi_description_en"></b-form-textarea>
                            <VValidate 
                              name="Description (EN)" 
                              v-model="slideRow.ahi_description_en"
                              :rules="{...mrValidation.ahi_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                  <div class="col-md-12">
                    <b-card class="border mb-0">
                      <template #header>
                        <h5 class="card-title">Content (JP)</h5>
                      </template>
                      <b-row>
                        <b-col md=6>
                          <div class="form-group">
                            <label class="control-label">Title (JP)<span class="text-danger">*</span></label>
                            <b-form-input type="text" placeholder="Title" v-model="slideRow.ahi_title_jp" />
                            <VValidate 
                              name="Title (JP)" 
                              v-model="slideRow.ahi_title_jp"
                              :rules="mrValidation.ahi_title_jp" 
                            />
                          </div>
                        </b-col>
                        <b-col md=6>
                          <div class="form-group mb-0">
                            <label class="control-label">Description (JP)</label>
                            <b-form-textarea class="form-control" rows="3" placeholder="Description" v-model="slideRow.ahi_description_jp"></b-form-textarea>
                            <VValidate 
                              name="Description (JP)" 
                              v-model="slideRow.ahi_description_jp"
                              :rules="mrValidation.ahi_description_jp" 
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                  <div class="col-12">
                    <b-card class="border border-top-0 my-0">
                      <div class="row align-items-center">
                        <div class="col-md-auto">
                          <div class="form-group mb-0">
                            <div class="custom-control custom-control-inline custom-checkbox">
                              <b-form-checkbox id="has_button" value="Y" unchecked-value="N" name="has_button" v-model="slideRow.ahi_has_button">Has Button?</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </div>
                  <template v-if="slideRow.ahi_has_button == 'Y'">
                    <div class="col-md-12">
                      <b-card class="border m-0 border-top-0">
                        <b-row>
                          <b-col md=6>
                            <div class="form-group">
                              <label class="control-label">Button Label (ID) <span class="text-danger">*</span></label>
                              <b-form-input type="text" v-model="slideRow.ahi_button_label_id" />
                              <VValidate 
                                name="Button Label (ID)" 
                                v-model="slideRow.ahi_button_label_id"
                                :rules="{required: true}" 
                              />
                            </div>
                          </b-col>
                          <b-col md=6>
                            <div class="form-group mb-0">
                              <label class="control-label">Button Link (ID) <span class="text-danger mr5">*</span></label>
                              <b-form-input type="text" v-model="slideRow.ahi_button_link_id" />    
                              <VValidate 
                                name="Button Link (ID)" 
                                v-model="slideRow.ahi_button_link_id"
                                :rules="{required: true, regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </b-card>
                    </div>
                    <div class="col-md-12">
                      <b-card class="border m-0 border-top-0">
                        <b-row>
                          <b-col md=6>
                            <div class="form-group">
                              <label class="control-label">Button Label (EN) <span class="text-danger">*</span></label>
                                <b-form-input type="text" v-model="slideRow.ahi_button_label_en" />
                                <VValidate 
                                name="Button Label (EN)" 
                                v-model="slideRow.ahi_button_label_en"
                                :rules="{required: true, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                              />
                            </div>
                          </b-col>
                          <b-col md=6>
                            <div class="form-group">
                              <label class="control-label">Button Link (EN) <span class="text-danger mr5">*</span></label>
                              <b-form-input type="text" v-model="slideRow.ahi_button_link_en" />    
                              <VValidate 
                                name="Button Link (EN)" 
                                v-model="slideRow.ahi_button_link_en"
                                :rules="{required: true, regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </b-card>
                    </div>
                    <div class="col-md-12">
                      <b-card class="border m-0 border-top-0">
                        <b-row>
                          <b-col md=6>
                            <div class="form-group mb-2">
                              <label class="control-label">Button Label (JP) <span class="text-danger">*</span></label>
                                <b-form-input type="text" v-model="slideRow.ahi_button_label_jp" />
                                <VValidate 
                                name="Button Label (JP)" 
                                v-model="slideRow.ahi_button_label_jp"
                                rules="required" 
                              />
                            </div>
                          </b-col>
                          <b-col md=6>
                            <div class="form-group mb-0">
                              <label class="control-label">Button Link (JP) <span class="text-danger mr5">*</span></label>
                              <b-form-input type="text" v-model="slideRow.ahi_button_link_jp" />    
                              <VValidate 
                                name="Button Link (JP)" 
                                v-model="slideRow.ahi_button_link_jp"
                                :rules="{required: true, regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </b-card>
                    </div>
                  </template>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <div class="text-right">
              <b-button @click="() => sliderCollapse = false" class="btn-rounded" variant="outline-secondary">
                Cancel</b-button>
              <b-button type="submit" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
            </div>
          </b-card-footer>
        </b-form>
        </validation-observer>
      </b-card>
    </b-collapse>
  </bo-card>
</template>
<script>
import BoCard from '@/components/backend/BoCard.vue'
import BoCardImg from '@/components/backend/BoCardImg.vue'
import draggable from 'vuedraggable'
import Gen from '@/libs/Gen.js'

let _ = global._

export default {
  props: {
    modulePage: String
  },
  components: {
    BoCard, BoCardImg, draggable
  },
  data(){
    return {
      sliderData: [],
      sliderCollapse: null,
      autoplay: {},
      slideRow: {},
      mrValidation: {},
      typeOptions: [
        {label: 'Image', value: 'image'},
        {label: 'Video', value: 'video'},
      ]
    }
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    updateSlide(value){
      this.slideRow = _.clone(value)
      this.sliderCollapse = true
    },
    changeAhiType(){
      this.slideRow = {...this.slideRow, ahi_image:''}
    },
    endDrag(){

    },
    addSlider(){
      this.slideRow = { ahi_is_active: 'Y', ahi_has_button: 'N' }
      this.sliderCollapse = true
    },
    changeAutoPlay(debounce){
      if(!debounce){
        Gen.apiRest('/do/' + this.modulePage, 
          { data: { type: 'sliderAutoplay', ...this.autoplay } }
        ).then(res => {
          if(res){
            this.$swal({
              title: res.data.message,
              icon: 'success',
            }).then(() => {
              this.apiGet()
            })
          }
        }).catch(err => {
          this.$swal({title: err.response.data.message, icon:'error'})
        })
      }else{
        clearTimeout(window.timeout)
        window.timeout = setTimeout(() => {
          Gen.apiRest('/do/' + this.modulePage, 
              { data: { type: 'sliderAutoplay', ...this.autoplay } }
            ).then(res => {
              if(res){
                this.$swal({
                  title: res.data.message,
                  icon: 'success',
                }).then(() => {
                  this.apiGet()
                })
              }
            }).catch(err => {
              this.$swal({title: err.response.data.message, icon:'error'})
            })
        }, 700)
      }
    },
    doChangeStatus(index, item){
      console.log(index, item)
    },
    submitSlider(){
      this.$refs.VForm.validate().then(success => {
        if(!success) return

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
          { data: { type: this.slideRow.ahi_id ? 'updateSlider' : 'addSlider', ...this.slideRow } }
        ).then(res => {
          if(res){
            this.$swal({
              title: res.data.message,
              icon: 'success',
            }).then(() => {
              this.sliderCollapse = false
              this.apiGet()
            })
          }
        }).catch(err => {
          this.$swal({title: err.response.data.message, icon:'error'})
        })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/slider', {}, 'GET').then(res => {
        this.sliderData = res.data.slider
        this.mrValidation = res.data.validation
        this.autoplay = res.data.autoplay
      })
    }
  }
}
</script>
