<template>
  <b-card>
    <template #header>
      <h5 class="card-title">Flagship Product</h5>
    </template>
    <div class="d-flex align-items-center">              
      <template v-if="showContent">
        <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-flagship-product-home', apiGet)" id="kompetensi_layanan" value="Y" unchecked-value="N" name="kompetensi_layanan" v-model="showContent">Show Section?</b-form-checkbox>
      </template>
    </div>

    <hr />

    <template>
      <div class="d-flex align-items-center mb-2">              
        <b-button variant="info" class="btn-rounded ml-auto" @click="addFlagshipProduct">
          Add Flagship Product <i class="fas fa-plus ml-2"></i>
        </b-button>
      </div>
      <b-row>
        <div v-for="(value, index) in data" :key="index" class="col-md-3">
          <div class="facilities_wrap">
            <img :src="parent.uploader(value.image)" class="thumb_prod" alt="">
            <div :class="'overlay_facilities ' + (index % 2 == 0 ? 'bg_red_ic' : 'bg_green_ic')">
              <h3>{{value.title_id}}</h3>
            </div>
            <div class="wrap_fasilitas_action">
              <b-button
                variant="danger"
                size="sm"
                @click="deleteFlagship(index)"
                pill
                class="btn-icon"
                triggers="hover"
                v-b-tooltip.hover="'Delete'"
              >
                <i class="fas fa-trash" />
              </b-button>
              <b-button
                variant="warning"
                size="sm"
                pill
                @click="updateFlagship(value, index)"
                class="btn-icon"
                triggers="hover"
                v-b-tooltip.hover="'Update'"
              >
                <i class="fas fa-pencil-alt" />
              </b-button>
            </div>
          </div>
        </div>
      </b-row>
    </template>

    <b-modal id="modalFlagship" size="xl"  title="Form Flagship Product" no-close-on-backdrop>
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormFlagship"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitFlagship)">
          <b-card-body>
            <b-row>
              <b-col md=4>
                <div class="form-group">
                    <label class="control-label">Image <span class="text-danger">*</span></label>         
                    <Uploader v-model="dataModal.image" type="transmission_gear"/>
                    <VValidate 
                      name="Image" 
                      v-model="dataModal.image"
                      :rules="validation.image" 
                    />
                </div>
              </b-col>
              <b-col md=8>
                <b-row>
                  <b-col md=6>
                    <b-form-group label-for="titleId">
                      <label>Title (ID) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_id"></b-form-input>
                      <VValidate name="Title (ID)" v-model="dataModal.title_id" :rules="validation.title_id" />
                    </b-form-group>
                    <b-form-group label-for="descId">
                      <label>Description (ID)</label>
                      <b-form-textarea v-model="dataModal.desc_id"></b-form-textarea>
                      <VValidate name="Description (ID)" v-model="dataModal.desc_id" :rules="validation.desc_id" />
                    </b-form-group>
                  </b-col>
                  <b-col md=6>
                    <b-form-group label-for="titleEN">
                      <label>Title (EN) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_en"></b-form-input>
                      <VValidate name="Title (EN)" v-model="dataModal.title_en" :rules="validation.title_en" />
                    </b-form-group>
                    <b-form-group label-for="descEN">
                      <label>Description (EN)</label>
                      <b-form-textarea v-model="dataModal.desc_en"></b-form-textarea>
                      <VValidate name="Description (EN)" v-model="dataModal.desc_en" :rules="validation.desc_en" />
                    </b-form-group>
                  </b-col>
                  <b-col md=6>
                    <b-form-group label-for="titleJP">
                      <label>Title (JP) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.title_jp"></b-form-input>
                      <VValidate name="Title (JP)" v-model="dataModal.title_jp" :rules="validation.title_jp" />
                    </b-form-group>
                    <b-form-group label-for="descJP">
                      <label>Description (JP)</label>
                      <b-form-textarea v-model="dataModal.desc_jp"></b-form-textarea>
                      <VValidate name="Description (JP)" v-model="dataModal.desc_jp" :rules="validation.desc_jp" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-form>
      </validation-observer>
      <template #modal-footer="{close}">
        <div class="text-right">
          <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
          <b-button type="button" @click="doSubmitFlagship" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'

export default {
  props:{
    modulePage: String
  },
  data(){
    return {
      data: [],
      validation: {},
      showContent: null,
      dataModal: {}
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  }, 
  methods: {
    addFlagshipProduct(){
      this.dataModal = { desc_id: '', desc_en: '', desc_jp: '' }
      this.$bvModal.show('modalFlagship')
    },
    updateFlagship(value, index){
      this.dataModal = _.clone(value)
      this.dataModal.index = index
      this.dataModal.isUpdate = true
      this.$bvModal.show('modalFlagship')
    },
    doSubmitFlagship(){
      this.$refs.VFormFlagship.validate().then(success => {
        if(!success) return

        this.parent.swalLoading()

        let isAdd = null

        if(this.dataModal.isUpdate){
          const index = this.dataModal.index
          delete this.dataModal.index
          delete this.dataModal.isUpdate
          this.data[index] = this.dataModal
          isAdd = false
        }else{
          this.data.push(this.dataModal)
          isAdd = true
        }

        Gen.apiRest('/do/' + this.modulePage, 
          { data: { type: 'updateFlagship', data: this.data, isAdd } }
        ).then(res => {
          if(res){
            this.$swal({
              title: res.data.message,
              icon: 'success',
            }).then(() => {
              this.apiGet()
              this.$bvModal.hide('modalFlagship')
            })
          }
        }).catch(err => {
          this.$swal({title: err.response.data.message, icon:'error'})
        })
      })
    },
    deleteFlagship(index){
      this.$swal({
        title: 'Delete this Flagship Product?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.value){
          const data = _.clone(this.data)
          data.splice(index, 1)
          Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'deleteFlagship', data: data} }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
        }
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/flagship', {}, 'GET').then(res => {
        this.data = res.data.data
        this.validation = res.data.validation
        this.showContent = res.data.showContent
      })
    }
  }
}
</script>
<style scoped>
.facilities_wrap {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}
.thumb_prod {
    transition: transform 0.5s;
}
.overlay_facilities {
    position: absolute;
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    left: 0;
    bottom: 0;
}
.bg_red_ic {
    background-color: #c92027;
}
.bg_green_ic {
    background-color: #39768e;
}
.overlay_facilities h3 {
    padding: 0;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
}
.wrap_fasilitas_action {
  position: absolute;
  z-index: 8;
  top: 16px;
  right: 16px;
  display: flex;
}
.wrap_fasilitas_action .btn {
  margin-left: 8px;
}
.facilities_wrap .badge {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 8;
}
</style>