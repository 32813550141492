<template>
  <b-card>
    <template #header>
      <h5 class="card-title">Preview Company</h5>
    </template>
    <div class="d-flex align-items-center">              
      <template v-if="showContent">
        <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-preview-company', apiGet)" id="preview" value="Y" unchecked-value="N" name="preview" v-model="showContent">Show Section?</b-form-checkbox>
      </template>
      <b-button 
        variant="secondary"
        class="btn-rounded ml-auto"
        v-if="previewOpen"
        @click="closePreviewForm">
        Close <i class="fas fa-times ml-2"></i>
      </b-button>
      <b-button
        variant="outline-info"
        class="btn-rounded ml-auto"
        @click="showPreviewUpdate"
        v-else
      >
        Update Content <i class="fas fa-pencil-alt ml-2"></i>
      </b-button>

    </div>
    <hr />

    
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="VFormPreview"
      tag="div"
      v-if="previewOpen"
    >
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-card class="border mb-0">
          <template #header>
            <h5 class="card-title">Content</h5>
          </template>
          <b-row class="mb-2">
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Image <span class="text-danger">*</span></label>         
                <Uploader v-model="row.asc_image" type="preview_company"/>
                <VValidate 
                  name="Image" 
                  v-model="row.asc_image"
                  :rules="validation.asc_image" 
                />
              </div>
            </b-col>
            <b-col md=8>
              <b-row>
                <div class="col-12">
                  <b-card class="border my-0">
                    <div class="row align-items-center">
                      <div class="col-md-auto">
                        <div class="form-group mb-0">
                          <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox id="has_button_preview" value="Y" unchecked-value="N" name="has_button_preview" v-model="row.asc_has_button">Has Button?</b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </div>
                <template v-if="row.asc_has_button == 'Y'">
                  <div class="col-md-12">
                    <b-card class="border m-0 border-top-0">
                      <b-row>
                        <b-col md=6>
                          <div class="form-group">
                            <label class="control-label">Button Label (ID) <span class="text-danger">*</span></label>
                            <b-form-input type="text" v-model="row.asc_button_label_id" />
                            <VValidate 
                              name="Button Label (ID)" 
                              v-model="row.asc_button_label_id"
                              :rules="{required: true}" 
                            />
                          </div>
                        </b-col>
                        <b-col md=6>
                          <div class="form-group mb-0">
                            <label class="control-label">Button Link (ID) <span class="text-danger mr5">*</span></label>
                            <b-form-input type="text" v-model="row.asc_button_link_id" />    
                            <VValidate 
                              name="Button Link (ID)" 
                              v-model="row.asc_button_link_id"
                              :rules="{required: true}"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                  <div class="col-md-12">
                    <b-card class="border m-0 border-top-0">
                      <b-row>
                        <b-col md=6>
                          <div class="form-group">
                            <label class="control-label">Button Label (EN) <span class="text-danger">*</span></label>
                              <b-form-input type="text" v-model="row.asc_button_label_en" />
                              <VValidate 
                              name="Button Label (EN)" 
                              v-model="row.asc_button_label_en"
                              :rules="{required: true, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </div>
                        </b-col>
                        <b-col md=6>
                          <div class="form-group">
                            <label class="control-label">Button Link (EN) <span class="text-danger mr5">*</span></label>
                            <b-form-input type="text" v-model="row.asc_button_link_en" />    
                            <VValidate 
                              name="Button Link (EN)" 
                              v-model="row.asc_button_link_en"
                              :rules="{required: true}"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                  <div class="col-md-12">
                    <b-card class="border m-0 border-top-0">
                      <b-row>
                        <b-col md=6>
                          <div class="form-group mb-2">
                            <label class="control-label">Button Label (JP) <span class="text-danger">*</span></label>
                              <b-form-input type="text" v-model="row.asc_button_label_jp" />
                              <VValidate 
                              name="Button Label (JP)" 
                              v-model="row.asc_button_label_jp"
                              rules="required" 
                            />
                          </div>
                        </b-col>
                        <b-col md=6>
                          <div class="form-group mb-0">
                            <label class="control-label">Button Link (JP) <span class="text-danger mr5">*</span></label>
                            <b-form-input type="text" v-model="row.asc_button_link_jp" />    
                            <VValidate 
                              name="Button Link (JP)" 
                              v-model="row.asc_button_link_jp"
                              :rules="{required: true}"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                </template>
              </b-row>
            </b-col>
          </b-row>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (ID) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_id"
                  ref="inputBriefCompanyID"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_id"

                />
                <VValidate 
                  name="Content (ID)" 
                  v-model="row.asc_content_id"
                  :rules="validation.asc_content_id" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (EN) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_en"
                  ref="inputBriefCompanyEN"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_en"

                />
                <VValidate 
                  name="Content (EN)" 
                  v-model="row.asc_content_en"
                  :rules="validation.asc_content_en" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (JP) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_jp"
                  ref="inputBriefCompanyJP"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_jp"

                />
                <VValidate 
                  name="Content (JP)" 
                  v-model="row.asc_content_jp"
                  :rules="validation.asc_content_jp" 
                />
              </div>
            </div>
          </div>
          <template #footer>
            <div class="text-right">
              <b-button variant="secondary" class="btn-rounded mr-3" @click="closePreviewForm">Cancel</b-button>
              <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
            </div>
          </template>
        </b-card>
      </b-form>
    </validation-observer>
    <div class="row" v-else>
      <div class="col-md-8">
        <div v-html="row.asc_content_id"></div>
        <div v-if="row.asc_has_button == 'Y'" class="company-preview-cta mt-4">
          <a :href="row.asc_button_link_id" class="cta cta--secondary cta--arrow">
            {{row.asc_button_label_id}}<i class="ti-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <img :src="parent.uploader(row.asc_image)" alt="">
      </div>
    </div>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
import CKEditor from '@/components/CKEditor'
// let _ = global._

export default {
  components: {
    CKEditor
  },
  props:{
    modulePage: String
  },
  data(){
    return {
      previewOpen: false,
      row: {},
      configCKEditor: [ 'heading', '|', 'bold','italic','underline','strikethrough','link','bulletedList','numberedList','blockQuote'],
      validation: {},
      showCompanyBrief: false,
      showContent: null
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  }, 
  methods: {
    showPreviewUpdate(){
      this.previewOpen = true
    },
    closePreviewForm(){
      this.previewOpen = false
    },
    doSubmit(){
      this.$refs.VFormPreview.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updatePreview', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.previewOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/preview_company', {}, 'GET').then(res => {
        this.row = res.data.row
        this.validation = res.data.validation
        this.showContent = res.data.showContent
      })
    }
  }
}
</script>
<style scoped>
.cta {
  font-size: 14px;
  border-radius: 0 0px 20px;
  text-transform: uppercase;
  color: #fff !important;
  padding: 10px 28px;
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  font-weight: 622300;
  position: relative;
  transition: 0.25s ease-in-out;
}

.cta--arrow {
  padding-right: 56px;
}

.cta--secondary {
  background-color: #c92027;
  border-color: #c92027;
}
.cta--arrow i {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    width: 40px;
    border-radius: 0 0px 20px;
    background-color: rgba(0, 0, 0, .125);
    display: block;
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    transition: 0.25s ease-in-out;
}
</style>