<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <SliderSection v-if="moduleRole('slider')" :modulePage="modulePage" @delete="doDelete(...arguments)" />
          <CTASection v-if="moduleRole('cta')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <PreviewSection v-if="moduleRole('preview')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <FlagshipSection v-if="moduleRole('flagship_section')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <ProductCatSection v-if="moduleRole('category_product')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <KompetensiLayanan v-if="moduleRole('kompetensi')" @delete="doDelete(...arguments)" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <LiniProduksi v-if="moduleRole('lini_produk')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <MediaSection v-if="moduleRole('media')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <ClientSection v-if="moduleRole('client')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import SEOSection from '@/components/SEOSection'
import SliderSection from './SliderSection.vue'
import CTASection from './CTASection.vue'
import PreviewSection from './PreviewSection.vue'
import ProductCatSection from './ProductCatSection.vue'
import FlagshipSection from './FlagshipSection.vue'
import KompetensiLayanan from './KompetensiLayanan.vue'
import LiniProduksi from './LiniProduksi.vue'
import MediaSection from './MediaSection.vue'
import ClientSection from './ClientSection.vue'

export default {
  name: 'BoHome',
  extends: GlobalVue,
  components: {
    SliderSection,
    SEOSection,
    CTASection,
    PreviewSection,
    ProductCatSection,
    KompetensiLayanan,
    LiniProduksi,
    MediaSection,
    ClientSection,
    FlagshipSection
  },
  mounted() {
    this.apiGet()
  },
  data() {
    return {
    }
  },
  methods: {
    updateShowSection(value, slug, callback){

      Gen.apiRest('/do/' + this.modulePage, {data: {type: 'updateShowSetting', value, slug}}).then(res => {
        if(res){
          this.$swal({
            title: res.data.message,
            icon: 'success',
          }).then(() => {
            callback()
          })
        }
      }).catch(err => {
        this.$swal({title: err.response.data.message, icon:'error'})
      })
    },
    doDelete(v, params = {}, callback){

      let id = v[params.idKey]
      let obj = params.obj||this.$route.meta.objName
      this.$swal({
        title: 'Delete this ' + obj + '?' || 'Data' + '?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.swalLoading()

          Gen.apiRest("/do/"+this.modulePage,{data:{type:params.type,id:id}}, "POST")
            .then((resp = {})=>{
              this.$swal({
                title: resp.data.message || 'Deleted Successfully!',
                icon: 'success',
              }).then(() => {
                callback()
              })
            })
            .catch(err=>{ 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response.data.title
                  err.message = err.response.data.message
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })        
        }
      })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
  }
}
</script>