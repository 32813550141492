<template>
  <b-card>
    <template #header>
      <h5 class="card-title">CTA Category</h5>
    </template>
    <div class="d-flex align-items-center">              
      <template v-if="showContent">
        <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-home-cta-category', apiGet)" id="ctaCat" value="Y" unchecked-value="N" name="ctaCat" v-model="showContent">Show Section?</b-form-checkbox>
      </template>
    </div>
    <hr />
    <b-row>
      <b-col md=4 v-for="(value, index) in cta" :key="index">
        <div class="wrap_cta_front border">
          <div class="row align-items-center">
            <div class="col-md-4">
              <div class="wrap_ic_cta">
                <img :src="parent.uploader(value.image)" alt="">
              </div>
            </div>
            <div class="col-md-8">
              <p>{{ value.title_id }}</p>
            </div>
            <div class="cta_action">
              <b-button
                variant="warning"
                size="sm"
                pill
                @click="updateCTA(value)"
                class="btn-icon"
                triggers="hover"
                v-b-tooltip.hover="'Update'"
              >
                <i class="fas fa-pencil-alt" />
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="modalCTA" size="xl"  title="Form CTA" no-close-on-backdrop>
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormCTA"
      >
        <b-form @submit.prevent="handleSubmit(doSubmit)">
          <b-card-body>
            <b-row>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Image <span class="text-danger">*</span></label>         
                  <Uploader v-model="row.image" type="cta"/>
                  <VValidate 
                    name="Image" 
                    v-model="row.image"
                    :rules="validation.image" 
                  />
                </div>
              </b-col>
              <b-col md=8>
                <b-row>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (ID) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.title_id"></b-form-input>
                      <VValidate name="Title (ID)" v-model="row.title_id" :rules="validation.title_id" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (EN) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.title_en"></b-form-input>
                      <VValidate name="Title (EN)" v-model="row.title_en" :rules="validation.title_en" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (JP) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.title_jp"></b-form-input>
                      <VValidate name="Title (JP)" v-model="row.title_jp" :rules="validation.title_jp" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Link (ID) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.link_id"></b-form-input>
                      <VValidate name="Link (ID)" v-model="row.link_id" :rules="validation.link_id" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Link (EN) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.link_en"></b-form-input>
                      <VValidate name="Link (EN)" v-model="row.link_en" :rules="validation.link_en" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Link (JP) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.link_jp"></b-form-input>
                      <VValidate name="Link (JP)" v-model="row.link_jp" :rules="validation.link_jp" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-form>
      </validation-observer>
      <template #modal-footer="{close}">
        <div class="text-right">
          <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
          <b-button type="button" @click="doSubmit" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>
<style scoped>
.wrap_cta_front{background-color:#81b2c6;}
</style>
<script>
import Gen from '@/libs/Gen.js'


let _ = global._

export default {
  props: {
    modulePage: String,
  },
  data(){
    return {
      cta: [],
      validation: {},
      row: {},
      showContent: null,
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    doSubmit(){
      this.$refs.VFormCTA.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateCTA', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$bvModal.hide('modalCTA')
                this.apiGet()
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    updateCTA(value){
      this.row = _.clone(value)
      this.$bvModal.show('modalCTA')
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/cta', {}, 'GET').then(res => {
        this.cta = res.data.cta
        this.showContent = res.data.showContent
        this.validation = res.data.validation
      })
    }
  }
}
</script>
<style>
  .wrap_cta_front {
    position: relative;
    padding: 20px;
    cursor: pointer;
  } 
  .wrap_ic_cta {
    position: relative;
    padding: 20px;
    width: 100px;
    height: 100px;
    display: inline-block;
    border-radius: 100%;
  }
  .wrap_cta_front p {
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 18px;
  }
  .cta_action {
    position: absolute;
    top: 20px;
    right: 20px;
  }
</style>
