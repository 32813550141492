<template>
  <b-card>
    <template #header>
      <h5 class="card-title">Product Category</h5>
    </template>
    <div class="d-flex align-items-center">              
      <template v-if="showContent">
        <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-product-category', apiGet)" id="product_cat" value="Y" unchecked-value="N" name="product_cat" v-model="showContent">Show Section?</b-form-checkbox>
      </template>
      <b-button 
        variant="secondary"
        class="btn-rounded ml-auto"
        v-if="productCatOpen"
        @click="closeProductForm">
        Close <i class="fas fa-times ml-2"></i>
      </b-button>
      <b-button
        variant="outline-info"
        class="btn-rounded ml-auto"
        @click="showProductUpdate"
        v-else
      >
        Update Content <i class="fas fa-pencil-alt ml-2"></i>
      </b-button>

    </div>
    <hr />

    
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="VFormProductCat"
      tag="div"
      v-if="productCatOpen"
    >
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-card class="border mb-0">
          <template #header>
            <h5 class="card-title">Content</h5>
          </template>
          <b-row class="mb-2">
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Description (ID) <span class="text-danger">*</span></label>         
                <b-form-textarea :rows="3" v-model="row.desc_id"  />
                <VValidate 
                  name="Description (ID)" 
                  v-model="row.desc_id"
                  rules="required|min:3" 
                />
              </div>
            </b-col>
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Description (EN) <span class="text-danger">*</span></label>         
                <b-form-textarea :rows="3" v-model="row.desc_en"  />
                <VValidate 
                  name="Description (EN)" 
                  v-model="row.desc_en"
                  rules="required|min:3" 
                />
              </div>
            </b-col>
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Description (JP) <span class="text-danger">*</span></label>         
                <b-form-textarea :rows="3" v-model="row.desc_jp"  />
                <VValidate 
                  name="Description (JP)" 
                  v-model="row.desc_jp"
                  rules="required|min:3" 
                />
              </div>
            </b-col>
          </b-row>
          <template #footer>
            <div class="text-right">
              <b-button variant="secondary" class="btn-rounded mr-3" @click="closeProductForm">Cancel</b-button>
              <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
            </div>
          </template>
        </b-card>
      </b-form>
    </validation-observer>
    <div class="row" v-else>
      <div class="col-md-3 col-6">
        <div class="heading_product">
          <p>{{row.desc_id}}</p>
          <div class="triangle_up">
            <img src="assets/images/corner.png">
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
// let _ = global._

export default {
  props:{
    modulePage: String
  },
  data(){
    return {
      productCatOpen: false,
      row: {},
      validation: {},
      showContent: null,
      productCategory: []
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  }, 
  methods: {
    showProductUpdate(){
      this.productCatOpen = true
    },
    closeProductForm(){
      this.productCatOpen = false
    },
    doSubmit(){
      this.$refs.VFormProductCat.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateProductCat', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.productCatOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/product_category', {}, 'GET').then(res => {
        this.row = res.data.row
        this.validation = res.data.validation
        this.showContent = res.data.showContent
      })
    }
  }
}
</script>
<style scoped>
.heading_product {
  position: relative;
  padding: 30px;
  height: 202px;
  background: #2f6479;
  align-items: center;
  justify-content: center;
  display: flex;
}
.heading_product p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
    line-height: 1.75;
}
.triangle_up {
  position: absolute;
  width: 0;
  top: 0;
  width: 30px;
  right: 0;
  height: 0;
}
.wrap_product_item {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
}
.overlay_item_prod {
  position: absolute;
  width: 100%;
  height: auto;
  padding: 5px;
  background-color: rgb(201 32 39 / 60%);
  left: 0;
  bottom: 0;
}
.overlay_item_prod h4 {
  color: #fff;
  padding: 0px 0 0 0;
  text-align: center;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
</style>